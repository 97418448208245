<a
  href="https://coopmaths.fr/www/cahiers-de-vacances"
  target="_blank"
  class="flex flex-col items-center mx-auto max-w-lg p-3 rounded-lg shadow
    bg-coopmaths-canvas-dark border border-gray-200 hover:bg-gray-200
    dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
>
  <h5 class="mb-2 text-2xl font-bold tracking-tight text-coopmaths-struct dark:text-white">
    Nouveau
  </h5>
  <div class="flex space-x-2">
    <img
      src="/alea/assets/images/cahier_vers_la_2nde.png"
      alt="Cahier de vacances vers la première spécialité"
      class="max-w-36 mb-4 flex"
    />
    <img
      src="/alea/assets/images/cahier_vers_la_1ere_spe.png"
      alt="Cahier de vacances vers la première spécialité"
      class="max-w-36 mb-4 flex"
    />
    <img
      src="/alea/assets/images/cahier_vers_la_terminale.png"
      alt="Cahier de vacances vers la terminale spécialité"
      class="max-w-36 mb-4 flex"
    />
  </div>
  <p class="text-center font-normal text-gray-700 dark:text-gray-400">
    Découvrez les cahiers de vacances pour le lycée général.
  </p>
</a>
