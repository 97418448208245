<script lang="ts">
  import type { Objective } from '../../../../types/objective'

  export let objective: Objective
</script>

<h2 class="subtitle
  text-xl md:text-3xl"
>
  Rappel du cours
</h2>
<div class="p-6">
  {#if objective.lessonSummaryHTML}
    <p
      id="lessonSummaryHTML"
      contenteditable="false"
      bind:innerHTML={objective.lessonSummaryHTML}
    />
  {/if}
  {#if objective.lessonSummaryImage}
    <img
      src={objective.lessonSummaryImage}
      alt={objective.lessonSummaryImageAlt}
    />
  {/if}
  {#if objective.lessonSummaryInstrumenpoche}
    <div class="text-center">
      <div class="inline-block" id="divIEP"></div>
    </div>
  {/if}
</div>
