export const unitsReferences = <const> [
  'S6S1',
  'S6S2',
  'S6S3',
  'S6S4',
  'S6S5',
  'S6S6',
  'S6S7',
  'S6S8',
  'S6S9',
  'S6S10',
  'S6S11',
  'S6S12',
  'S6S13',
  'S6S14',
  'S6S15',
  'S6S16',
  'S6S17',
  'S6S18',
  'S6S19',
  'S6S20',
  'S6S21',
  'S6S22',
  'S6S23',
  'S6S24',
  'S6S25',
  'S6S26',
  'S6S27',
  'S6S28',
  'S6S29',
  'S5S1',
  'S5S2',
  'S5S3',
  'S5S4',
  'S5S5',
  'S5S6',
  'S5S7',
  'S5S8',
  'S5S9',
  'S5S10',
  'S5S11',
  'S5S12',
  'S5S13',
  'S5S14',
  'S5S15',
  'S5S16',
  'S5S17',
  'S5S18',
  'S5S19',
  'S5S20',
  'S5S21',
  'S5S22',
  'S5S23',
  'S5S24',
  'S5S25',
  'S5S26',
  'S5S27',
  'S4S1',
  'S4S2',
  'S4S3',
  'S4S4',
  'S4S5',
  'S4S6',
  'S4S7',
  'S4S8',
  'S4S9',
  'S4S10',
  'S4S11',
  'S4S12',
  'S4S13',
  'S4S14',
  'S4S15',
  'S4S16',
  'S4S17',
  'S4S18',
  'S4S19',
  'S4S20',
  'S4S21',
  'S4S22',
  'S4S23',
  'S4S24',
  'S4S25',
  'S3S1',
  'S3S2',
  'S3S3',
  'S3S4',
  'S3S5',
  'S3S6',
  'S3S7',
  'S3S8',
  'S3S9',
  'S3S10',
  'S3S11',
  'S3S12',
  'S3S13',
  'S3S14',
  'S3S15',
  'S3S16',
  'S3S17',
  'S3S18',
  'S3S19',
  'S3S20',
  'S3S21',
  'S3S22',
  'S3S23'
]
