<script lang="ts">
  import {
    isTeacherMode,
    isPersonalMode,
    objectives
  } from '../../../services/store'
  import { emptyObjective, type Objective } from '../../../types/objective'
  import { getTitle } from '../../../services/shared'
  import { goToView } from '../../../services/navigation'
  import { onMount, tick } from 'svelte'
  import {
    mathaleaRenderDiv
  } from '../../../../lib/mathalea'
  import Cart from '../../../modules/Cart'
  import iepLoadPromise from 'instrumenpoche'
  import BoutonsExercices from '../../shared/BoutonsExercices.svelte'
  import DownloadLine from '../../shared/ListItemPdfDownload.svelte'
  import { isEmptyArrayRecord, deepCopy } from '../../../types/shared'
    import ObjectiveRegularLessonSummary from './presentationalComponents/ObjectiveRegularLessonSummary.svelte';
    import ObjectiveRegularVideos from './presentationalComponents/ObjectiveRegularVideos.svelte';
    import ObjectiveRegularExercises from './presentationalComponents/ObjectiveRegularExercises.svelte';

  export let objectiveReference: string

  let objective: Objective = deepCopy(emptyObjective)
  let isAllExercisesInCart = false
  let isExamExercisesInCart = false

  onMount(() => {
    objective = $objectives.find(objectif => objectif.reference === objectiveReference) || deepCopy(emptyObjective)
    updateExercisesInCart()
    renderLessonSummary()
  })

  function updateExercisesInCart (): void {
    for (const exercise of objective.exercises) {
      exercise.isInCart = Cart.includes(exercise.id)
    }
    isAllExercisesInCart = objective.exercises.every(exercise => exercise.isInCart)
    for (const exercise of objective.examExercises) {
      exercise.isInCart = Cart.includes(exercise.id)
    }
    isExamExercisesInCart = objective.examExercises.every(exercise => exercise.isInCart)
  }

  async function renderLessonSummary (): Promise<void> {
    if (objective.lessonSummaryHTML) {
      await tick()
      const lessonSummaryHTML = document.getElementById('lessonSummaryHTML')
      if (lessonSummaryHTML !== null) mathaleaRenderDiv(lessonSummaryHTML, -1)
    }
    if (objective.lessonSummaryInstrumenpoche) loadIep()
  }

  function loadIep (): void {
    const url = `topmaths/data/instrumenpoche/${objective.lessonSummaryInstrumenpoche}.xml`
    fetch(url)
      .then(response => response.text())
      .then(xml => {
        const container = document.getElementById('divIEP')
        iepLoadPromise(container, xml).catch((error: Error) => {
          console.error(error)
        })
      })
      .catch(error => {
        console.error(error)
      })
  }
</script>

<svelte:head>
  <title>{objective.reference + ' : ' + getTitle(objective)}</title>
</svelte:head>

<div class="grade-container is-{objective.grade}
  rounded-4xl md:rounded-5xl">
  <h1 class="title
    text-2xl md:text-4xl
    rounded-t-4xl md:rounded-t-5xl"
  >
    {objective.reference + ' : ' + getTitle(objective)}
  </h1>
  {#if objective.lessonSummaryHTML || objective.lessonSummaryImage || objective.lessonSummaryInstrumenpoche}
    <ObjectiveRegularLessonSummary
      {objective}
    />
  {/if}
  {#if objective.videos.length > 0}
    <ObjectiveRegularVideos
      videos={objective.videos}
    />
  {/if}
  {#if objective.exercises.length > 0}
    <ObjectiveRegularExercises
      objective={objective}
      isAllExercisesInCart={isAllExercisesInCart}
    />
  {/if}
  {#if objective.examExercisesLink !== ''}
    <div class="">
      <h2 class="subtitle text-xl md:text-3xl p-3 ">En route vers le brevet</h2>
      <div class="pt-6 text-sm md:text-base">
        Tu ne peux pas encore faire ces exercices en entier mais avec ce que tu
        as appris tu sais répondre à au moins une question de chacun
        d'entre eux !
      </div>
      <ul class="p-6 ">
        <li class="p-2">
          <BoutonsExercices
            exercices = {objective.examExercises}
            videos = {objective.videos}
            lienExercices = {objective.examExercisesLink}
            panierRempli = {isExamExercisesInCart}
            titre = {'Lancer les exercices de brevet'}
            exercicesDeBrevet = {true}
            nomsPanier = {[objective.reference + ' ' + getTitle(objective) + ' Brevet ']}
          />
        </li>
      </ul>
    </div>
  {/if}
  {#if objective.downloadLinks.practiceSheetLink ||
    ($isTeacherMode && objective.downloadLinks.testSheetLink) ||
    ($isPersonalMode && !isEmptyArrayRecord(objective.downloadLinks.lessonPlanLinks))}
    <div
      class="{objective.units.length === 0 ? 'is-end ' : ''}"
    >
      <h2 class="subtitle text-xl md:text-3xl p-3 ">Téléchargements</h2>
      <ul class="p-6 ">
        <DownloadLine
          displayCondition={!!objective.downloadLinks.practiceSheetLink}
          href={objective.downloadLinks.practiceSheetLink}
          label="Télécharger la feuille d'entraînement"
        />
        <DownloadLine
          displayCondition={$isTeacherMode && !!objective.downloadLinks.testSheetLink}
          href={objective.downloadLinks.testSheetLink}
          label="Télécharger les tests"
        />
        {#if $isPersonalMode && !isEmptyArrayRecord(objective.downloadLinks.lessonPlanLinks)}
          {#each Object.keys(objective.downloadLinks.lessonPlanLinks) as grade}
            {#if objective.downloadLinks.lessonPlanLinks[grade].length > 0}
              {#each objective.downloadLinks.lessonPlanLinks[grade] as lessonPlanLink, i}
                <DownloadLine
                  displayCondition={true}
                  href={lessonPlanLink}
                  label="Télécharger la fiche {objective.downloadLinks.lessonPlanLinks[grade].length > 1 ? i + 1 : '' } pour le niveau {grade}"
                />
              {/each}
            {/if}
          {/each}
        {/if}
      </ul>
    </div>
  {/if}
  {#if objective.units.length > 0}
    <div class="is-end ">
      <h2 class="subtitle text-xl md:text-3xl p-3 ">
        Séquence{objective.units.length > 1 ? 's' : ''}
      </h2>
      <p class="pt-8">Cet objectif fait partie de :</p>
      <ul class="p-6">
        {#each objective.units as sequence}
          <li class="p-1 md:p-2">
            <a
              href="/?v=sequence&ref={sequence.reference}"
              style="color: var(--base{sequence.reference.slice(1, 2)}e) !important;"
              on:click={(event) =>
                goToView(event, 'unit', sequence.reference)}
            >
              {'Séquence ' +
                sequence.reference.slice(3) +
                ' : ' +
                sequence.title}
            </a>
          </li>
        {/each}
      </ul>
    </div>
  {/if}
</div>
