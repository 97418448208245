<script lang="ts">
  import { getTitle } from '../../../../services/shared'
  import type { Objective } from '../../../../types/objective'
  import BoutonsExercices from '../../../shared/BoutonsExercices.svelte'

  export let objective: Objective
  export let isAllExercisesInCart: boolean

</script>

<h2 class="subtitle text-xl md:text-3xl p-3 ">
  <BoutonsExercices
    reference = {objective.reference}
    exercices = {objective.exercises}
    videos = {objective.videos}
    lienExercices = {objective.exercisesLink}
    panierRempli = {isAllExercisesInCart}
    titre = {'S\'entraîner'}
  />
</h2>
<ul class="p-6 ">
  {#each objective.exercises as exercice, i}
    <li class="p-1 md:p-2">
      <BoutonsExercices
        reference = {objective.reference}
        exercices = {[objective.exercises[i]]}
        videos = {objective.videos}
        lienExercices = {exercice.link}
        panierRempli = {exercice.isInCart}
        titre = {exercice.description !== ''
          ? exercice.description
          : objective.exercises.length > 1
            ? 'Exercices de niveau ' + (i + 1)
            : "Lancer l'exercice"}
        indiceExercice = {i}
        nomsPanier = {[objective.reference + ' ' + getTitle(objective)]}
      />
    </li>
  {/each}
</ul>
